// do not add something else in this file, just let this function

import { PARAM_SELECTION_PAGE } from "selection-page/model"

export function isSelectionPage(): boolean {
  let search = ""
  if (window.location.search && window.location.search.length > 1) {
    search = window.location.search.substring(1) // remove "?"
  }
  const searchParameters = getParametersFromString(search)
  if (searchParameters.hasOwnProperty(PARAM_SELECTION_PAGE)) {
    return searchParameters[PARAM_SELECTION_PAGE] === "true"
  }
  return false
}

/**
 * Ex. if params = "myvar=test&myothervar=test2"
 * Will return the object { myvar: "test", myothervar: "test2" }
 */
function getParametersFromString(params: string): { [key: string]: string } {
  const parameters: { [key: string]: string } = {}
  if (!params || params.length < 1) {
    return parameters
  }
  const entries: string[] = params.split("&")
  for (const entry of entries) {
    const split: string[] = entry.split("=")
    if (split.length < 2) {
      continue
    }
    parameters[decodeURI(split[0])] = decodeURI(split[1])
  }
  return parameters
}
