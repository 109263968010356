// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../fonts/barlow/stylesheet.css";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/action/edit.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jmap_wrapper html,\n.jmap_wrapper body {\n  padding: 0px;\n  margin: 0px;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n}\n\n.jmap_wrapper .jmapcloud-ng-map {\n  max-height: 100%;\n  min-height: 100%;\n}\n\n.jmap_wrapper .jmapcloud-ng-layout {\n  max-height: 100%;\n  max-width: 100%;\n  min-height: 100%;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  z-index: 4;\n}\n\n.jmap_wrapper .jmapcloud-ng-mouseover-edit-icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  width: 1rem;\n  height: 1rem;\n  filter: opacity(.7);\n}\n\n.jmap_wrapper .jmapcloud-ng-mouseover-edit-icon:hover {\n  cursor: pointer;\n  filter: opacity(1);\n}\n", "",{"version":3,"sources":["webpack://./src/resources/styles/main.css"],"names":[],"mappings":"AAEA;;EAEE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,yDAAgD;EAChD,4BAA4B;EAC5B,2BAA2B;EAC3B,sBAAsB;EACtB,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB","sourcesContent":["@import \"../fonts/barlow/stylesheet.css\";\n\nhtml,\nbody {\n  padding: 0px;\n  margin: 0px;\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n}\n\n.jmapcloud-ng-map {\n  max-height: 100%;\n  min-height: 100%;\n}\n\n.jmapcloud-ng-layout {\n  max-height: 100%;\n  max-width: 100%;\n  min-height: 100%;\n  position: absolute;\n  top: 0px;\n  left: 0px;\n  z-index: 4;\n}\n\n.jmapcloud-ng-mouseover-edit-icon {\n  background-image: url(../images/action/edit.png);\n  background-repeat: no-repeat;\n  background-position: center;\n  background-size: cover;\n  width: 1rem;\n  height: 1rem;\n  filter: opacity(.7);\n}\n\n.jmapcloud-ng-mouseover-edit-icon:hover {\n  cursor: pointer;\n  filter: opacity(1);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
