export function loadscript(url: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      const scriptTag = document.createElement("script")
      scriptTag.onload = () => resolve()
      scriptTag.onerror = () => reject()
      scriptTag.src = url
      document.head.appendChild(scriptTag)
    } catch (ex) {
      reject(ex)
    }
  })
}
