import { loadscript } from "app/tools/loadscript"
import "regenerator-runtime/runtime"
import "resources/styles/main.css"
import { isSelectionPage } from "selection-page/tools/entry"
import "swiper/css"

if (isSelectionPage()) {
  // just load the selection page
  import("selection-page/tools/start")
    .then(module => {
      const parent = window.opener
      if (!parent || !parent.JMap) {
        alert("This page must be opened only from JMap App")
        return
      }
      window.JMap = parent.JMap
      module.startSelectionPage()
    })
    .catch(error => console.error("Cannot load startSelectionPage method !", error))
} else {
  // here cannot use JMap.loadJSFile because JMap is not yet loaded...
  loadscript(CORE_INDEX_FILE_URL)
    .then(() =>
      import("app/tools/start")
        .then(module => module.start())
        .catch(error => console.error("Cannot load start method !", error))
    )
    .catch(error => console.error(error))
}

export default "ok"
